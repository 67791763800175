<template>
  <vue-timepicker
    :placeholder="placeholder"
    format="HH:mm"
    v-model="time"
    manual-input
  ></vue-timepicker>
</template>

<script>
const VueTimepicker = () => import("vue2-timepicker");
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "DefaultTimePicker",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: [String, Number]
    },
    placeholder: {
      type: String
    }
  },
  components: { VueTimepicker },
  data() {
    return {
      value: "",
      time: ""
    };
  },
  beforeDestroy() {
    this.$root.$off(`time-new-child:change_${this.unique}`);
  },
  mounted() {
    this.$root.$on(`timeChild:change_${this.unique}`, (value) => {
      this.time = value;
    });
    if (this.editValue) {
      this.time = this.editValue;
    }
  },
  methods: {
    Change__Time__Default() {
      this.value = this.time;
      const data = {
        eventName: "Change__Time__Default",
        data: {
          unique: this.unique,
          value: this.value
        }
      };
      this.$root.$emit(`timepicker:change_${this.unique}`, this.value);
    }
  },
  computed: {},
  watch: {
    time() {
      this.Change__Time__Default();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.vue__time-picker {
  width: 70px;

  /deep/ {
    input {
      width: 100%;
      height: 32px;
      padding: 7px 7px 4px 7px;

      font-family: SF, sans-serif;
      color: $color-text-primary-1;
      font-weight: 400;
      font-size: 14px;
      line-height: 213px;

      outline: none;
      border: 1px solid $color-text-secondary;
      background-color: transparent;
      border-radius: 5px;
    }

    .controls {
      .clear-btn {
        .char {
          position: relative;
          width: 15px;
          height: 15px;

          font-size: 0;

          &:hover {
            &::after {
              transform: scale(0.95);
            }
          }

          &::after {
            position: absolute;
            content: "";
            width: 15px;
            height: 15px;

            background-repeat: no-repeat;
            background-image: url("~@/assets/img/icons/clearInputDate.svg");
            background-size: 100%;
            transition: all 0.2s ease;
          }
        }
      }
    }

    .dropdown {
      top: calc(2.2em + 0.5%);
      overflow: hidden;
      border: 1px solid $color-text-secondary;
      box-shadow: none;
      border-radius: 10px;

      .select-list {
        .hint {
          display: none;
        }

        li {
          font-family: SF, sans-serif;
          color: $color-text-primary-1;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          transition: all 0.2s ease;

          &:hover {
            background-color: $color-interactive;
          }

          &.active {
            background-color: $color-interactive;

            &:hover {
              background-color: $color-interactive;
            }
          }
        }
      }
    }
  }
}
</style>
